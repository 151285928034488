import Head from 'next/head'
import Link from 'next/link'

const Page = () => {
  return (
    <>
      <Head>
        <title>Not Found</title>
      </Head>
      <div className="flex flex-col items-center justify-center p-4">
        <h1 className="text-3xl">お探しのページは見つかりませんでした</h1>
        <div className="aspect-square 1/2 md:w-1/3">
          <img src="/404.png" alt="困ったロボット" width="100%" height="100%" />
        </div>
        <Link href="/">
          <a className="text-primary-700 text-xl font-semibold">トップに戻る</a>
        </Link>
      </div>
    </>
  )
}


export default Page
